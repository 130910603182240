import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Home from './Components/pages/Home';
import Navbar from '../src/Components/navSection/NavBar';
import ContactUs from './Components/ContactPage/Contact';
import ContactBar from './Components/contactbar/Contactbar';
import './App.css';
import Procurement from './Components/pages/Procurement/Procurement';
import ExpressionofInterest from './Components/pages/ExpressionofInterest/Vacancy';
import AboutPage from './Components/pages/Aboutpage/AboutPage';
import Footer from './Components/footerSection/Footer';
import Preloader from './../src/Components/Preloader/Preloader';
import Projects from './Components/ProjectsPage/Project';
import GarissaCountyActionPlan from './Components/Posts/GarissaCountyPlan';
import LivestockMarketPost from './Components/Posts/LivestockMarketPost';
import IOMPost from './Components/Posts/IOMPost';
import LiveliHoodPost from './Components/Posts/LivelihoodProject';
import OutofschoolPost from './Components/Posts/OutofSchoolProject';
import ThematicAreas from './Components/Thematicarea/ThematicArea';
import WomenEmpowerment from './Components/Posts/WomenEmpower';
import SIRRProjectPost from './Components/Posts/SIRRProject';
import CommunityResiliencePost from './Components/Posts/CommunityResilience';
import ResilienceAdaptationPost from './Components/Posts/ResilienceAdaptation';
import NotFoundPage from './Components/pages/NotFoundPage/NotFoundPage';
// import ProjectsPage from './Components/pages/ProjectsPage/ProjectsPage';


const App = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay with setTimeout
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000); // Simulating 3 seconds of loading time

        // Cleanup function to clear the timer
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {/* Conditionally render Preloader component based on loading state */}
            {loading ? (
                <Preloader />
            ) : (
                <>
            {/* <div className="App"> */}
                <ContactBar />
                <Navbar /> 
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about-us" element={<AboutPage />} />
                    <Route path="/thematic-areas" element={<ThematicAreas/>} />
                    <Route path="/projects" element={<Projects />} />
    
                    <Route>
                        <Route path="/work-with-us/procurement" element={<Procurement />} />
                        <Route path="/work-with-us/expression-of-interest" element={<ExpressionofInterest />} />

                    </Route>
                    <Route path="/contact-us" element={<ContactUs />} />


                   {/* Project Post Routes */}
                    <Route path="/garissa-county-action-plan-cve" element={<GarissaCountyActionPlan />} />
                    <Route path="/livestock-market-systems-strengthening-communities-capacities-for-resilience-and-growth-lms" element={<LivestockMarketPost />} />
                    <Route path="/iom" element={<IOMPost />} />
                    <Route path="/livelihood" element={<LiveliHoodPost/>} />
                    <Route path="/the-out-of-school-children-project" element={<OutofschoolPost/>} />
                    <Route path="/women-empowerment" element={<WomenEmpowerment />}/>
                    <Route path="/sirr-project" element={<SIRRProjectPost />}/>
                    <Route path="/community-resilience" element={<CommunityResiliencePost />}/>
                    <Route path="/resilience-adaptation-project-garissa-county" element={<ResilienceAdaptationPost />}/>

                    {/* Always keep the NotFoundPage route at the end */}
                   <Route path="*" element={<NotFoundPage />} />
    
                </Routes>
                <Footer />
            {/* </div> */}
        </>
    )}
    </>
    );
}

export default App;
