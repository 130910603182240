import React from 'react';
import './GarissaCountyActionPlan.css';
import PhotoGallery4 from '../PhotoGallery/PhotoG4';

const LiveliHoodPost = () => {
  return (
    <div>
    <div className="garissa-county-action-plan">
      <h3>Livelihood Project</h3>
      <p className="post-description">
      ROAD with support from IHH Humanitarian Relief Foundation did urgent food and medical AID to the refugee population of Daadab and the host community.
      </p>

      <div className="objectives">
        <h2>Purpose for the project:</h2>
        <p className="post-description">The goal of the project was to reduce the loss of lives and livelihoods through appropriate and timely interventions in response to the prevailing drought condition through the provision of food, shelter and emergency medical support. The project was aimed to reduce malnutrition of people who were hard hit by the prevailing drought.
        </p>
        <br/>
        <h2>Benefits and beneficiaries</h2>
        <p className="post-description">The project aimed to support 3000 household that were most affected by the current drought and each household had about 6 household members. These households that were targeted were the most vulnerable with highest malnutrition rate. This households were identified through an assessment.
        </p>
      </div>
    </div>
    
   
    <PhotoGallery4 />
  </div>
  );
  
};


export default LiveliHoodPost;


