/* ContactBar.js */
import React from 'react';
import './ContactBar.css';
import { FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaLinkedin, FaYoutube } from 'react-icons/fa';

const ContactBar = () => {
  return (
    <div className="contactBar">
      <div className="contactInfo">
        <span className="phone">
          <FaPhone /> 020 7855149
        </span>
        <span className="email">
          <FaEnvelope /> info@road-international.org
        </span>
      </div>
      <div className="socialIcons2">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
          <FaYoutube />
        </a>
      </div>
    </div>
  );
};

export default ContactBar;
