import React from 'react';
import './GarissaCountyActionPlan.css';
import PhotoGallery3 from '../PhotoGallery/PhotoG3';

const IomPost1 = () => {
  return (
    <div>
    <div className="garissa-county-action-plan">
      <h1 className="post-title">IOM</h1>
      <p className="post-description">IOM in Bakol Elbadre district. Renovation of borehole and water tracking.</p>
    </div>
    
    <PhotoGallery3 />
  </div>
  );
  
};


export default IomPost1;


