import React from 'react';
import './GarissaCountyActionPlan.css';
import PhotoGallery5 from '../PhotoGallery/PhotoG5';

const OutofschoolPost = () => {
  return (
    <div>
    <div className="garissa-county-action-plan">
      <h1 className="post-title"></h1>
      <p className="post-description">
      ROAD with funding from UNICEF is implementing the “Operation come to School” programme. The programme recognizes the specific needs and the unique contextual factors that shape the access to quality educational opportunity for marginalized children in Garissa County of Bringing back 10,125 out of school children.
      </p>
      <br />
      <p>Sponsored by:</p>
      <h1>Unicef</h1>
    </div>
    
   
    <PhotoGallery5 />
  </div>
  );
  
};


export default OutofschoolPost;


