// SidebarData.jsx
export const sidebarData = [
  {
    title2: 'Home',
    url2: '/',
    submenuItems2: [],
  },
  {
    title2: 'About Us',
    url2: '/about-us',
    submenuItems2: [],
  },
  {
    title2: 'Thematic Areas',
    url2: '/thematic-areas',
    submenuItems2: [],
  },
  {
    title2: 'Projects',
    url2: '/projects',
    submenuItems2: [],
  },
  {
    title2: 'Work with Us',
    url2: '#', 
    submenuItems2: [
      {
        title2: 'Expression of Interest',
        url2: '/work-with-us/expression-of-interest',
      },
      {
        title2: 'Tenders',
        url2: '/work-with-us/procurement',
      },
    ],
  },

  {
    title2: 'Contact Us',
    url2: '/contact-us',
    submenuItems2: [],
  },

];

  