export const menuItems = [

  {
    title: 'Home',
    url: '/',
    submenuItems: [],
  },
  {
    title: 'About Us',
    url: '/about-us',
    submenuItems: [],
  },
  {
    title: 'Thematic Areas',
    url: '/thematic-areas',
    submenuItems: [],
  },
  {
    title: 'Projects',
    url: '/projects',
    submenuItems: [],
  },
  {
    title: 'Work with Us',
    url: '/#', 
    submenuItems: [
      {
        title: 'Expression of Interest',
        url: '/work-with-us/expression-of-interest',
      },
      {
        title: 'Tenders',
        url: '/work-with-us/',
      },
    ],
  },

  {
    title: 'Contact Us',
    url: '/contact-us',
    submenuItems: [],
  },

];
