import React from 'react';
import './GarissaCountyActionPlan.css';
import PhotoGallery6 from '../PhotoGallery/PhotoG6';

const CommunityResiliencePost = () => {
  return (
    <div>
    <div className="garissa-county-action-plan">
      <h1 className="post-title">Promoting Community Resilience to Conflicts and Violent Extremism (2023-2024)</h1>
      <p className="post-description">
      This initiative focuses on fostering community resilience to conflicts and violent extremism in Garissa County. Implemented from 2023 to 2024, the project aims to build stronger communities capable of withstanding conflicts' impact and resisting the influence of violent extremism. It strategically addresses the root causes of conflicts, promoting sustainable peace and development in the region.
      </p>
      <br />
      <p>Sponsored by:</p>
      <h1></h1>
    </div>
    
   
    <PhotoGallery6 />
  </div>
  );
  
};


export default CommunityResiliencePost;


