import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import Sidebar from '../sidebar/Sidebar';
import { menuItems } from './navdata';
import './Navbar.css';
import logo from '../../Assets/logos/main-logo.jpg';

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState(null);

  const location = useLocation(); // Get the current location

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMediumScreen(window.innerWidth <= 991);
    };

    checkScreenSize();

    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const handleSubmenuOpen = (index) => {
    setActiveSubmenuIndex(index);
  };

  const handleSubmenuClose = () => {
    setActiveSubmenuIndex(null);
  };

  return (
    <>
      {isSidebarOpen && <Sidebar isOpen={isSidebarOpen} onCloseSidebar={handleToggleSidebar} />}
      <nav className="navbar1">
        <div className="logo-container1">
          <img src={logo} alt="Logo" className="logo" />
          <span className="company-name">ROAD</span>
        </div>
        {isMediumScreen && (
          <div className="hamburger-menu" onClick={handleToggleSidebar}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        )}
        <ul className="menu1">
          {menuItems.map((menuItem, index) => (
            <li
              key={index}
              className="menu-item1"
              onMouseEnter={() => handleSubmenuOpen(index)}
              onMouseLeave={handleSubmenuClose}
            >
              <Link
                to={menuItem.url}
                className={location.pathname === menuItem.url ? 'activeItem' : ''} 
              >
                {menuItem.title}
              </Link>
              {menuItem.submenuItems.length > 0 && (
                <ul className={`submenu1 ${activeSubmenuIndex === index ? 'submenu-open1' : ''}`}>
                  {menuItem.submenuItems.map((submenuItem, subIndex) => (
                    <li key={subIndex} className="submenu-item1">
                      <Link
                        to={submenuItem.url}
                        className={location.pathname === submenuItem.url ? 'activeItem' : ''} 
                      >
                        {submenuItem.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
