import React from 'react';
import './GarissaCountyActionPlan.css';
import PhotoGallery6 from '../PhotoGallery/PhotoG6';

const SIRRProjectPost = () => {
  return (
    <div>
    <div className="garissa-county-action-plan">
      <h1 className="post-title">Supporting Initiatives in Rehabilitation and Reintegration (SIRR)" Project (Ongoing till 2026)</h1>
      <p className="post-description">
      Funded by GCERF, this project supports initiatives focused on rehabilitating and reintegrating individuals affected by violent extremism in Garissa County. With ongoing implementation until 2026, the project facilitates comprehensive rehabilitation efforts, contributing to peace and stability in the region.
      </p>
      <br />
      <p>Sponsored by:</p>
      <h1>GCERF</h1>
    </div>
    
   
    <PhotoGallery6 />
  </div>
  );
  
};


export default SIRRProjectPost;


