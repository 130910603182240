import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactUs.css';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const serviceId = 'service_qcfdb24';
    const templateId = 'template_799w8nd';
    const publicKey = 'OnQY9Nu5KiMpmLUAo';
    
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'Road International',
      subject: subject,
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then(
        (response) => {
          alert('Email sent successfully');
          // Reset the form fields
          setName('');
          setEmail('');
          setSubject('');
          setMessage('');
        }
      )
      .catch((error) => {
        alert('Error sending email. Please try again later.');
        console.error('Error sending email:', error);
      });
  };

  return (
    <div className="contact-us1">
      <h1 className="contact-title1">Contact Us</h1>
      <div className="contact-details1">
        <div className="contact-info1">
          <h2>Our Contacts</h2>
          <p>
            <strong>Cell:</strong> 020 7855149<br />
            <strong>Email:</strong> info@road-international.org<br />
            <strong>Address (Kenya):</strong> School Lane, Westlands, Nairobi, Kenya<br />
            <strong>Address (Somalia):</strong> Sinay, State House Road, Kismayo Jubaland State, Somalia<br />
            <strong>Address (Somaliland):</strong> Gantalaha area, Hargeisa, Somaliland
          </p>
        </div>
        <div className="contact-form1">
          <h2>You can also fill the form below and submit it to us.</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group1">
              <label htmlFor="name">Your Name (required)</label>
              <input
                type="text"
                id="name"
                name="name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group1">
              <label htmlFor="email">Your Email (required)</label>
              <input
                type="email"
                id="email"
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group1">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="form-group1">
              <label htmlFor="message">Your Message</label>
              <textarea
                id="message"
                name="message"
                rows="5"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <button type="submit" className="submit-button1">
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
