import React from 'react';
import './GarissaCountyActionPlan.css';
import PhotoGallery2 from '../PhotoGallery/PhotoG2';

const LivestockMarketPost = () => {
  return (
    <div>
    <div className="garissa-county-action-plan">
      <h1 className="post-title">Livestock Market Systems – Strengthening Communities Capacities for Resilience and Growth (LMS).
      <br /> Background to LMS Program:</h1>
      <p className="post-description">
      Mercy Corps, a leading global organization powered by the belief that a better world is possible, together with ACDI/VOCA is implementing a five-year USAID funded Feed the Future Livestock Market Systems (LMS) Activity for northern Kenya. The project’s premise is that through collective action and strengthened formal and informal institutions, systems, governance, and human capital, people’s resilience to shocks and stresses is strengthened. Thus, the goal of the program is to reduce the prevalence and depth of chronic poverty, hunger, and under-nutrition. The program hopes to improve in the following specific areas: strength and sustainability (including rangeland and water management), drought risk management, conflict management, health, nutrition, and hygiene practices, improved literacy, numeracy, and life skills, as well as collaborative action and learning for community empowerment.

Rights Organization for Advocacy and Development (ROAD) International have been sub-contracted to implement the LMS program under Associate Award 2.

During the contract period, ROAD International will be implementing LMS program by forming and capacity building two Ward Planning Committees in Balambala and Waberi while supporting advocacy and influencing of other 3 established WPCs.

The mandate also covers WASH and COVID social behavior changes targeting the household through community engagement mechanisms.
      </p>

      <div className="objectives">
        <h2>1. Strengthen Institutions, Systems and Governance:</h2>
        <ul>
          <li>
          Supporting 2 wards (A peri-urban and a rural wards) in establishing and building the capacity of Ward Development Planning.
          </li>
          <li>
          Strengthened and sustained rangeland and water management.
          </li>
          <li>
          Strengthened drought risk management.
          </li>
          <li>
          Strengthened conflict management.
          </li>
        </ul>

        <h2>2. Improved human capital.</h2>
        <ul>
          <li>
          Improved and sustained health, nutrition and hygiene practices & covid-19 prevention activities.
          </li>
          <li>
          To program targets two wards; Waberi and Balambala peri-urban and rural wards respectively. The WARD DEVELOPMENT PLANNING PROCESS TOOLKIT developed by Mercy Corps guides the process.
          </li>
        </ul>
      </div>
    </div>
    
   
    <PhotoGallery2 />
  </div>
  );
  
};


export default LivestockMarketPost;


