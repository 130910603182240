import React from 'react';
import './GarissaCountyActionPlan.css';


const WomenEmpowerment = () => {
  return (
    <div>
    <div className="garissa-county-action-plan">
      <h1 className="post-title">Women Empowerment</h1>
      <p className="post-description">In a world where women's empowerment is key to progress, ROAD International is dedicated to showcasing impactful projects. We believe that empowered women are change-makers, shaping communities and societies. Together, we can create a world where women are celebrated for their remarkable contributions. Join us in this transformative journey for women's empowerment.</p>
    </div>
    
   
  </div>
  );
  
};


export default WomenEmpowerment;


