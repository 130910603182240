import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './PhotoGallery.css'

import Image1 from '../../Assets/images/photos/DSC_6348-min.jpg';
import Image2 from '../../Assets/images/photos/DSC_6350-min.jpg';
import Image3 from '../../Assets/images/photos/DSC_6354-min.jpg';
import Image4 from '../../Assets/images/photos/DSC_6367-min.jpg';
import Image5 from '../../Assets/images/photos/DSC_6372-min.jpg';
import Image6 from '../../Assets/images/photos/DSC_6375-min.jpg';
import Image7 from '../../Assets/images/photos/DSC_6379-min.jpg';
import Image8 from '../../Assets/images/photos/DSC_6383-min.jpg';
import Image9 from '../../Assets/images/photos/DSC_6394-min.jpg';
import Image10 from '../../Assets/images/photos/DSC_6405-min.jpg';
import Image11 from '../../Assets/images/photos/DSC_6412-min.jpg';
import Image12 from '../../Assets/images/photos/DSC_6430-min.jpg';
import Image13 from '../../Assets/images/photos/DSC_6447-min.jpg';
import Image14 from '../../Assets/images/photos/DSC_6468-min.jpg';
import Image15 from '../../Assets/images/photos/DSC_6471-min.jpg';
import Image16 from '../../Assets/images/photos/DSC_6478-min.jpg';
import Image17 from '../../Assets/images/photos/DSC_6552-min.jpg';



const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11, Image12, Image13, Image14, Image15, Image16, Image17];

const PhotoGallery = () => {
  return (
    <div className="photo-gallery">
      <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default PhotoGallery;
