import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './PhotoGallery.css'

import Image1 from '../../Assets/images/LivestockProject1/website-Road2.png';
import Image2 from '../../Assets/images/LivestockProject1/website-Road.png';
import Image3 from '../../Assets/images/LivestockProject1/website-Road3.png';
import Image4 from '../../Assets/images/LivestockProject1/website-Road4.png';
import Image6 from '../../Assets/images/LivestockProject1/website-Road6.png';



const images = [Image1, Image2, Image3, Image4,  Image6];

const PhotoGallery2 = () => {
  return (
    <div className="photo-gallery">
      <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default PhotoGallery2;
